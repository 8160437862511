import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Test from "../components/Test";

const Testing = ({ text, duration, backgroundImages }) => {
   
    return(
        <Test></Test>
    )
}

export default Testing;
