import React, { useState, useEffect } from 'react';

const textData = [
  { text: "Welcome to the demo.", duration: 1 },
  { text: "This is an example of looping text.", duration: 3 },
  { text: "Enjoy the animations and sounds!", duration: 4 },
];

const Test = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [voicesLoaded, setVoicesLoaded] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);

  useEffect(() => {
    // Load voices when the component mounts
    const loadVoices = () => {
      if (window.speechSynthesis.getVoices().length !== 0) {
        setVoicesLoaded(true);
      } else {
        window.speechSynthesis.onvoiceschanged = () => {
          setVoicesLoaded(true);
        };
      }
    };
    loadVoices();
  }, []);

  useEffect(() => {
    if (!isSpeaking || !voicesLoaded) return; // Wait for voices and user interaction

    const synth = window.speechSynthesis;
    synth.cancel(); // Cancel any pending utterances

    const currentText = textData[currentIndex].text;
    const utterance = new SpeechSynthesisUtterance(currentText);

    // Speak the current text
    synth.speak(utterance);

    // Switch to the next text after the specified duration
    const timer = setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === textData.length - 1 ? 0 : prevIndex + 1
      );
    }, textData[currentIndex].duration * 1000); // Convert duration to milliseconds

    // Cleanup
    return () => {
      synth.cancel();
      clearTimeout(timer);
    };
  }, [currentIndex, voicesLoaded, isSpeaking]);

  // Start speaking when the user clicks the button
  const handleStartSpeaking = () => {
    setIsSpeaking(true);
  };

  return (
    <div>
      <h1>{textData[currentIndex].text}</h1>
      {!isSpeaking && (
        <button onClick={handleStartSpeaking}>Start Speech</button>
      )}
    </div>
  );
};

export default Test;
